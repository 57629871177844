<template>
  <v-dialog v-model="dialog" width="400">
    <v-card class="pa-3">
      <!-- <v-card-title /> -->
      <v-card-text class="black--text pa-0 pb-2">
        {{ info }}
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer />
        <v-btn
          color="green"
          tile
          elevation="0"
          class="white--text"
          @click="agree"
          >Да</v-btn
        >
        <v-btn
          color="red"
          tile
          elevation="0"
          class="white--text"
          @click="cancel"
          >Отменить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogAlert",
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      width: 300,
    },
  }),

  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.$store.dispatch("closeAlert");
    },
  },
  computed: {
    info() {
      return this.$store.getters.getInfoAlert;
    },
  },
  provide: function() {
    return { agree: this.agree, cancel: this.cancel };
  },
};
</script>
