<template>
  <v-form>
    <div>
      <error-info :error="'name' in errorsValidation">
        {{ errorsValidation.name }}
      </error-info>
      <v-row no-gutters class="mb-4 d-flex align-center">
        <v-col cols="2">
          <span class="black--text">Название</span>
        </v-col>
        <v-col>
          <v-text-field
            v-model="dataForm.name"
            autofocus
            dense
            outlined
            hide-details
            class="base-text-field"
          />
        </v-col>
      </v-row>
    </div>
    <div>
      <v-row no-gutters class="mb-4 d-flex align-center">
        <v-col cols="2">
          <span class="black--text">Описание</span>
        </v-col>
        <v-col>
          <v-text-field
            dense
            outlined
            hide-details
            class="base-text-field"
            v-model="dataForm.description"
          />
        </v-col>
      </v-row>
    </div>

    <v-card-actions>
      <v-spacer />
      <v-btn
        tile
        elevation="0"
        dense
        color="green"
        class="white--text text-capitalize"
        @click="onSubmitForm"
      >
        Сохранить
      </v-btn>
      <v-btn
        tile
        color="lightBlue"
        elevation="0"
        dense
        @click="onRejectForm"
        class="text-capitalize white--text"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import { convertPermissions } from "@/utils/adminDashboard/getAllRoles.js";
import ErrorInfo from "../Dialogs/ErrorInfo.vue";

export default {
  components: { ErrorInfo },
  name: "FormRole",
  props: ["value"],
  inject: ["agree", "cancel"],
  data() {
    return {
      convertPermissions,
      selectedRoles: [],
      errorsValidation: {},
    };
  },
  methods: {
    onSubmitForm() {
      if (!this.validate()) return;
      this.agree();
    },
    onRejectForm() {
      this.errorsValidation = {};
      // this.dataForm = {};
      this.cancel();
    },
    validate() {
      this.errorsValidation = {};
      if (
        !this.dataForm.name ||
        this.dataForm.name.length < 3 ||
        this.dataForm.name.length > 100
      ) {
        this.errorsValidation.name =
          "Наименование должно быть от 3 до 100 символов";
      }
      if (Object.keys(this.errorsValidation).length === 0) return true;
      return false;
    },
  },

  computed: {
    dataForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.base-list-group {
  & .v-list-group__header {
    padding: 0 !important;
  }
  & .v-list-item__title {
    font-size: 12px !important;
  }
}

.block-permissions {
  display: flex;
  flex-wrap: wrap;
  gap: 2em;

  font-size: 12px !important;
}
</style>
