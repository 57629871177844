<template>
  <div>
    <v-container fluid class="py-0 pb-3">
      <div class="py-3" style="width: 300px">
        <v-text-field
          hide-details
          outlined
          class="base-text-field"
          label="Поиск"
          v-model="searchString"
          @keydown.enter.prevent="onSearchRoles(searchString)"
          clearable
        >
          <v-icon
            slot="append-outer"
            color="blue"
            class="pointer"
            @click="onSearchRoles(searchString)"
          >
            mdi-magnify
          </v-icon>
        </v-text-field>
      </div>

      <v-btn
        tile
        elevation="0"
        color="green"
        class="white--text text-capitalize"
        height="27"
        @click="createRole"
      >
        <span>Создать</span>
      </v-btn>
    </v-container>
    <div class="wrapper" :style="wrapperStyles">
      <v-row class="table" no-gutters>
        <v-col cols="3" style="">
          <h2 class="table__header">Роли</h2>
          <div class="table__content">
            <v-list dense>
              <v-list-item-group v-model="selectedRole">
                <v-list-item
                  v-for="role in rolesList"
                  :key="role.id"
                  class="row-list"
                  @click="onClickRole(role)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="role.name"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div class="d-flex">
                      <v-btn icon @click.stop="editRole(role)">
                        <v-icon small>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                      <v-btn icon @click.stop="deleteRole(role)">
                        <v-icon color="red" small>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-col>
        <v-divider vertical style="z-index: 1" />
        <v-col>
          <h2 class="table__header">Права доступа</h2>
          <div class="table__content pa-2">
            <div v-if="Boolean(rolesList[selectedRole])">
              <div>
                <h3>
                  Описание
                </h3>
                <p>
                  {{ currentSelected.description }}
                </p>
              </div>
              <div
                v-for="permission in convertPermissions(permissionsList)"
                :key="String(permission.name)"
                class=""
              >
                <h3>
                  {{ permission.name }}
                </h3>
                <!-- {{ permission.privileges }} -->
                <!-- {{ rolesList[selectedRole].privileges[permission.value] }} -->
                <div class="d-flex">
                  <v-checkbox
                    label="Запись"
                    dense
                    class="base-checkbox mr-2"
                    :value="permission.privileges.write.id"
                    v-model="permissionsRole"
                    @change="onSelectWritePerm(permission.privileges.read.id)"
                  />
                  <v-checkbox
                    label="Чтение"
                    dense
                    :disabled="
                      permissionsRole.includes(permission.privileges.write.id)
                    "
                    class="base-checkbox mr-2"
                    :value="permission.privileges.read.id"
                    v-model="permissionsRole"
                  />
                  <v-checkbox
                    label="Удаление"
                    dense
                    class="base-checkbox mr-2"
                    v-if="permission.privileges.delete"
                    :value="permission.privileges.delete.id"
                    v-model="permissionsRole"
                  />
                </div>
              </div>

              <div>
                <v-btn
                  dense
                  tile
                  elevation="0"
                  color="green"
                  class="white--text text-capitalize"
                  height="27"
                  @click="onUpdatePermissions(currentSelected)"
                >
                  <span>Сохранить</span>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <dialog-alert ref="alert" />
    <dialog-wrapper
      ref="dialogCrud"
      :title="`${typeDialog === 'add' ? 'Создание' : 'Редактирование'} роли`"
    >
      <form-role v-model="editedRole" />
    </dialog-wrapper>

    <v-snackbar v-model="snackBar" top color="white" timeout="2000">
      <span class="black--text">
        {{ infoAlertText }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
import DialogWrapper from "./DialogWrapper.vue";
import FormRole from "./FormRole.vue";
import DialogAlert from "@/components/Dialogs/DialogAlert";
import {
  convertPermissions,
  getActivePermissions,
} from "@/utils/adminDashboard/getAllRoles.js";

export default {
  components: { DialogWrapper, FormRole, DialogAlert },
  name: "RoleTab",
  data() {
    const selectedRole = null;
    const searchString = "";
    const searchedString = "";
    const isSearching = false;
    const editedRole = {};
    const typeDialog = "add";
    const originalRole = {
      name: "",
      roles: [],
    };
    return {
      errorsValidation: {},
      originalRole,
      typeDialog,
      editedRole,
      selectedRole,
      searchString,
      searchedString,
      isSearching,
      convertPermissions,
      getActivePermissions,
      windowHeight: window.innerHeight,
      permissionsRole: [],
      snackBar: false,
      currentSelected: {},
      infoAlertText: "",
    };
  },
  mounted() {
    this.$store.dispatch("getAllRoles");
    this.$store.dispatch("getPersmissionsList");

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onSelectWritePerm(read_id) {
      this.permissionsRole.push(read_id);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    onClickRole(item) {
      this.permissionsRole = getActivePermissions(item);
      this.currentSelected = item;
    },
    onSearchRoles(search) {
      console.log(search);
      // if (!search.length) return (this.isSearching = false);
      // this.searchedString = search;
      // return (this.isSearching = true);
    },
    seeDetailsOfRole() {
      return this.selectedRole;
    },
    createPayloudUpdate(item) {
      return {
        id: item.id,
        name: item.name,
        privileges: item.roles,
        description: item.description,
      };
    },
    createPayloadNewItem(item) {
      return {
        name: item.name,
        privileges: [],
        description: item.description,
      };
    },
    editRole(item) {
      this.typeDialog = "edit";
      this.editedRole = Object.assign(
        { roles: getActivePermissions(item) },
        item
      );
      this.$refs.dialogCrud.open().then((res) => {
        if (res) {
          this.$store
            .dispatch("updateRole", this.createPayloudUpdate(this.editedRole))
            .then(() => {
              // alert("Успешно сохранен");
              this.infoAlertText = `Роль ${this.editedRole.name} успешно обновлена`;
              this.snackBar = true;
            });
        }
      });
    },
    onUpdatePermissions(role) {
      console.log(role);
      this.$store
        .dispatch(
          "updateRole",
          this.createPayloudUpdate({
            ...role,
            roles: this.permissionsRole,
          })
        )
        .then(() => {
          // alert("Успешно сохранен");
          this.infoAlertText = `Роль ${role.name} успешно обновлена`;
          this.snackBar = true;
        });
    },
    createRole() {
      this.typeDialog = "add";
      this.editedRole = Object.assign({}, this.originalRole);
      this.$refs.dialogCrud.open().then((res) => {
        if (res) {
          this.$store
            .dispatch("createRole", this.createPayloadNewItem(this.editedRole))
            .then(() => {
              this.infoAlertText = `Роль ${this.editedRole.name} успешно создана`;
              this.snackBar = true;
            });
        }
      });
    },
    deleteRole(item) {
      this.$store.dispatch(
        "callAlert",
        `Вы действительно хотите удалить роль ${item.name}`
      );
      this.$refs.alert.open().then((res) => {
        if (res) {
          this.$store.dispatch("deleteRole", item.id).then(() => {
            this.infoAlertText = `Роль ${item.name} успешно удалена`;
            this.snackBar = true;
          });
        }
      });
    },
  },
  computed: {
    permissionsList() {
      return this.$store.getters.getPermissionsList;
    },
    rolesList() {
      if (this.isSearching) {
        return this.$store.getters.getRolesList.filter((item) =>
          item.name.includes(this.searchedString)
        );
      }
      return this.$store.getters.getRolesList;
    },
    wrapperStyles() {
      return {
        // "max-height": `${this.windowHeight - 260}px`,
        // height: `${this.windowHeight - 260}px`,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  /* height: calc(100vh - 260px); */
  /* max-height: calc(100vh - 260px); */
  min-height: 100%;
  height: fill-available;
  /* height: 100%; */
}

.row-list {
  max-height: 32px;
}
.table {
  height: 100%;

  &__header {
    background-color: var(--color-lightGray-1);
    color: var(--color-black);
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
  }
  &__content {
    border: 1px solid var(--color-lightGray-2);
    border-collapse: collapse;
    min-height: 100%;
    height: 100%;
    /* max-height: fill-available; */
    /* overflow-y: scroll; */
    overflow: auto;

    & h3 {
      font-size: 12px;
      margin-bottom: 0.5em;
    }
  }
}
</style>
