const permissions = {
  LOCATION: {
    label: "Размещения",
    privileges: {
      LOCATION_DELETE_ACCESS: false,
      LOCATION_READ_ACCESS: false,
      LOCATION_WRITE_ACCESS: false,
    },
  },
  DEVICE: {
    label: "Устройства",
    privileges: {
      DEVICE_DELETE_ACCESS: false,
      DEVICE_READ_ACCESS: false,
      DEVICE_WRITE_ACCESS: false,
    },
  },
  MANAGEMENT: {
    label: "Администрирование",
    privileges: {
      MANAGEMENT_DELETE_ACCESS: false,
      MANAGEMENT_READ_ACCESS: false,
      MANAGEMENT_WRITE_ACCESS: false,
    },
  },
  PROFILE: {
    label: "Профили",
    privileges: {
      PROFILE_DELETE_ACCESS: false,
      PROFILE_READ_ACCESS: false,
      PROFILE_WRITE_ACCESS: false,
    },
  },
  REPORT: {
    label: "Отчёты",
    privileges: {
      REPORT_DELETE_ACCESS: false,
      REPORT_READ_ACCESS: false,
      REPORT_WRITE_ACCESS: false,
    },
  },
  RESERVATION: {
    label: "Бронирование",
    privileges: {
      RESERVATION_DELETE_ACCESS: false,
      RESERVATION_READ_ACCESS: false,
      RESERVATION_WRITE_ACCESS: false,
    },
  },
};

export const convertPermissions = (items) => {
  const res = [];
  for (const item in items) {
    // quick fix for permission none;
    // if (item === "NONE") continue;
    if (!(item in permissions)) continue;
    res.push({
      name: permissions[item].label,
      value: item,
      // privileges: items[item],
      privileges: {
        write: items[item].find((item) => item.name.includes("WRITE_ACCESS")),
        read: items[item].find((item) => item.name.includes("READ_ACCESS")),
        delete: items[item].find((item) => item.name.includes("DELETE_ACCESS")),
      },
    });
  }

  return res;
};

export const getActivePermissions = (item) => {
  const res = [];
  for (const i in item.privileges) {
    res.push(...item.privileges[i].map((type) => type.id));
  }
  return res;
};
